import { gql } from '@apollo/client';
import apollo from '@/lib/apollo-node';

export const GET_STORE = gql`
  query Store {
    store {
      id
      domain
      accessToken
      isSupplier
      isReseller
      currency
      lastProductSync
      settings
      data
      syncInterval
      subscription
      isBeta
      trialExpiresAt
      isTrial
      appInstallation
      plan
      trialUsed
      orderCount
      locales
      markets
    }
  }
`;

export const GET_ACCESS_TOKEN = gql`
  query storeAccessToken {
    store {
      id
      domain
      accessToken
      isBeta
    }
  }
`;

export const GET_STORE_CLIENT = gql`
  query StoreClient {
    store {
      id
      domain
      isSupplier
      isReseller
      isPrivate
    }
  }
`;

export const STORE_USER = gql`
  query StoreUser {
    store: storeUser {
      id
      accessToken
      accessScopes
      users {
        id
      }
    }
  }
`;

export const STORE_USER_SUBSCRIPTION = gql`
  query StoreUser {
    store: storeUser {
      id
      accessToken
      accessScopes
      appInstallation
      users {
        id
      }
    }
  }
`;

export const STORE_AND_USER = gql`
  query StoreAndUser {
    store {
      id
      domain
      accessToken
      supplierToken
      accessScopes
      users {
        id
      }
    }
  }
`;

export const CREATE_STORE = gql`
  mutation createStore($input: StoreInput!) {
    store: createStore(input: $input) {
      id
      domain
      accessToken
    }
  }
`;

export const UPDATE_STORE = gql`
  mutation storeUpdate($input: StoreUpdateInput!) {
    store: storeUpdate(input: $input) {
      id
      domain
      isSupplier
      isReseller
      settings
    }
  }
`;

export const FEATURES = gql`
  query Features {
    store {
      id
      features
    }
  }
`;

export const UPDATE_FEATURE = gql`
  mutation storeUpdateFeature($input: JSON!) {
    store: storeUpdateFeature(input: $input) {
      id
      features
    }
  }
`;

export const getStoreFromRequest = async (req) => {
  const { store: storeId, store_domain: domain } = req.user;
  const storeResult = await apollo.query({
    query: GET_STORE,
    context: {
      store: {
        id: storeId,
        domain,
      },
    },
  });

  return storeResult.data?.store;
};
