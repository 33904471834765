import { NuqsAdapter } from 'nuqs/adapters/next/pages';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import * as Sentry from '@sentry/react';
import { AppProvider, Frame } from '@shopify/polaris';
import AppBridgeProvider from '@/components/app-bridge';
import { UserContext } from '@/components/user';
import Redirect from '@/components/redirect';
import enTranslations from '@shopify/polaris/locales/en.json';
import { ShopifyVizProvider } from '@/components/shopify-viz';
import LangProvider, { LanguageContext } from './language';
import { ToastProvider, ToastContext } from './toast';

export { LanguageContext, ToastContext };
import styledTheme from '@/config/styled-theme';
import Link from 'next/link';

const LinkWrapper = ({ children, url, external, ...rest }) => {
  if (external) {
    return (
      <a href={url} target="_blank" {...rest}>
        {children}
      </a>
    );
  }
  return (
    <Link href={url} {...rest}>
      {children}
    </Link>
  );
};

const Providers = ({ children, apolloClient }) => (
  <NuqsAdapter>
    <AppProvider i18n={enTranslations} linkComponent={LinkWrapper}>
      <ShopifyVizProvider>
        <ApolloProvider client={apolloClient}>
          <AppBridgeProvider>
            <StyledThemeProvider theme={styledTheme}>
              <Sentry.ErrorBoundary
                fallback={
                  <p>An error has occurred. Please refresh your page.</p>
                }
              >
                <Redirect>
                  <UserContext>
                    <Frame>
                      <ToastProvider>
                        <LangProvider>{children}</LangProvider>
                      </ToastProvider>
                    </Frame>
                  </UserContext>
                </Redirect>
              </Sentry.ErrorBoundary>
            </StyledThemeProvider>
          </AppBridgeProvider>
        </ApolloProvider>
      </ShopifyVizProvider>
    </AppProvider>
  </NuqsAdapter>
);

export default Providers;
