import { Spinner } from '@shopify/polaris';
import dynamic from 'next/dynamic';

const PolarisVizProvider = dynamic(
  () =>
    import(
      '@shopify/polaris-viz/build/esm/components/PolarisVizProvider/PolarisVizProvider.js'
    ).then((mod) => mod.PolarisVizProvider),
  {
    // loading: () => <Spinner />,
    ssr: false,
  }
);

export default PolarisVizProvider;
