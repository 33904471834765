import { useState, useEffect } from 'react';
import Head from 'next/head';
import Script from 'next/script';
import Link from 'next/link';
import { Inter } from 'next/font/google';
import { createGlobalStyle } from 'styled-components';
import dynamic from 'next/dynamic';
import { useApollo } from '@/lib/apollo';
import * as snippet from '@segment/snippet';
import { Box } from '@shopify/polaris';
import { NavMenu } from '@shopify/app-bridge-react';
import Providers from '@/providers';

// Import styles in a separate file to avoid render blocking
import '@/styles/global.css';

// Dynamically import non-critical components
const AtlasSnippet = dynamic(() => import('../util/atlas-snippet'), {
  ssr: false,
});

const GlobalStyle = createGlobalStyle`
:root {
  --p-background: #F1F1F1;
}
html, body {
  /*
  background-color: #f5f6f7;
  font-family: "Inter", "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 12px;
  font-weight: 400; 
  */
}

a {
  text-decoration: none;
  color: inherit;
}

.Polaris-LegacyCard + .Polaris-LegacyCard {
  margin-top: var(--p-space-0) !important;
}

.Polaris-Frame__TopBar {
  height: 2.5rem;
}

.Polaris-Frame--hasTopBar .Polaris-Frame__Main {
  padding-top: 2.5rem;
}

.Polaris-Frame-ContextualSaveBar {
      height: 3rem;
}

.widget-preview {
  visibility: hidden;
}

.Polaris-IndexTable__TableRow.Polaris-IndexTable__TableRow--hovered .widget-preview {
  visibility: visible;
}



`;

function renderAnalyticsSnippet() {
  const opts = {
    apiKey: process.env.NEXT_PUBLIC_ANALYTICS_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    // page: true,
  };

  if (process.env.NODE_ENV === 'development') {
    return snippet.max(opts);
  }

  return snippet.min(opts);
}

const inter = Inter({ subsets: ['latin'] });

const App = ({ Component, pageProps = {}, host, apiKey, err }) => {
  const apolloClient = useApollo(pageProps);
  const [isClient, setIsClient] = useState(false);

  // prevents nextjs hydration mismatch
  // https://nextjs.org/docs/messages/react-hydration-error
  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null;
  }

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
        <link
          rel="preconnect"
          href="https://cdn.assortion.com"
          crossOrigin="anonymous"
        />
        <Script
          id="segment-script"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{ __html: renderAnalyticsSnippet() }}
        />
        <style jsx global>{`
          html {
            font-family: ${inter.style.fontFamily};
          }
        `}</style>
      </Head>
      <AtlasSnippet />
      <Providers apolloClient={apolloClient}>
        <NavMenu>
          <Link href="/" rel="home">
            Home
          </Link>
          <Link href="/cart" prefetch>
            Cart drawer
          </Link>
          <Link href="/analytics">Analytics</Link>
          <Link href="/settings">Settings</Link>
          <Link href="/plan">Plan</Link>
        </NavMenu>
        <GlobalStyle />
        <Box paddingBlockEnd="800">
          <Component {...pageProps} err={err} />
        </Box>
      </Providers>
    </>
  );
};

export default App;
