const breakpointSizes = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const breakpoints = {
  up: (breakpoint) =>
    `@media (min-width: calc(${breakpointSizes[breakpoint]} + 0.02px))`,
  down: (breakpoint) => `@media (max-width: ${breakpointSizes[breakpoint]})`,
  between: (min, max) =>
    `@media (min-width: calc(${breakpointSizes[min]} + 0.02px) and max-width: ${breakpointSizes[max]})`,
};

const layout = {
  padding: '24px',
  narrow: '960px',
};

export default {
  layout,
  breakpoints,
};
