import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { Pagination } from '@shopify/polaris';
import useDeepCompareEffect from 'use-deep-compare-effect';

const usePaginatedQuery = (
  query,
  options,
  limit = 20,
  fixedCount,
  initialPage = 1,
  onPageChange
) => {
  const { variables = {}, ...otherOptions } = options;
  const [page, setPage] = useState(initialPage);
  const offset = (page - 1) * limit;
  const fetchedOffset = useRef(offset);

  const handlePageChange = (newPage) => {
    setPage(newPage);
    onPageChange?.(newPage);
  };
  const result = useQuery(query, {
    notifyOnNetworkStatusChange: true,
    variables: {
      ...variables,
      offset,
      limit,
    },
    ...otherOptions,
  });

  const count =
    fixedCount || result.data?.count || result.data?.offersList?.count || 0;

  useEffect(() => {
    if (offset !== fetchedOffset.current) {
      result.fetchMore({
        variables: {
          ...variables,
          offset,
          limit,
        },
      });
      fetchedOffset.current = offset;
    }
  }, [offset, limit, variables]);

  useDeepCompareEffect(() => {
    handlePageChange(1);
  }, [variables]);

  const hasPreviousPage = offset > 0;
  const hasNextPage = offset + limit < count;

  const loadPreviousPage = () => {
    handlePageChange(page - 1);
  };

  const loadNextPage = () => {
    handlePageChange(page + 1);
  };

  const reset = () => {
    handlePageChange(1);
    fetchedOffset.current = 0;
  };

  const active = hasPreviousPage || hasNextPage;

  const paginationMarkup = active && (
    <Pagination
      hasPrevious={hasPreviousPage}
      onPrevious={loadPreviousPage}
      hasNext={hasNextPage}
      onNext={loadNextPage}
    />
  );

  return [
    result,
    {
      limit,
      page,
      totalPages: Math.ceil(count / limit),
      count,
      hasNextPage,
      hasPreviousPage,
      loadNextPage,
      loadPreviousPage,
      reset,
      active,
      paginationMarkup,
    },
  ];
};

export default usePaginatedQuery;
