import { useState, useCallback, createContext } from 'react';

export const LanguageContext = createContext();

export const LangProvider = ({ children }) => {
  const [language, setLanguage] = useState('default');
  const [market, setMarket] = useState('default');

  const changeLanguage = useCallback((lang) => {
    setLanguage(lang);
  }, []);

  const changeMarket = useCallback((selected) => {
    setMarket(selected);
  }, []);

  return (
    <LanguageContext.Provider
      value={{ language, changeLanguage, market, changeMarket }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LangProvider;
