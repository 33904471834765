import { gql } from '@apollo/client';

export const OFFER_FIELDS = gql`
  fragment OfferFields on Offer {
    id
    name
    type
    active
    data
    offerProducts {
      id
      attributes {
        title
        image
      }
    }
    triggerProducts {
      id
      attributes {
        title
        image
        handle
        onlineStoreUrl
      }
    }
    triggerExcludedProducts {
      id
      attributes {
        title
        image
        handle
        onlineStoreUrl
      }
    }
    impressions
    orders
    revenue
    createdAt
  }
`;

export const RECOMMENDATION_FIELDS = gql`
  fragment RecommendationFields on Recommendation {
    id
    offers {
      id
      attributes {
        title
        image
      }
    }
    triggers {
      id
      attributes {
        title
        image
      }
    }
  }
`;

export const OFFERS_LIST = gql`
  query offersList($input: OfferFilter, $offset: Int, $limit: Int) {
    offersList(input: $input, offset: $offset, limit: $limit) {
      offers {
        id
        name
        type
        active
        data
        offerProducts {
          id
          attributes {
            title
            image
          }
        }
        triggerProducts {
          id
          attributes {
            title
            image
            handle
            onlineStoreUrl
          }
        }
        impressions
        orders
        revenue
        createdAt
      }
      count
    }
  }
`;

export const OFFERS = gql`
  query offers($input: OfferFilter) {
    offers(input: $input) {
      ...OfferFields
    }
  }
  ${OFFER_FIELDS}
`;

export const OFFER = gql`
  query offer($id: ID!) {
    offer(id: $id) {
      ...OfferFields
    }
  }
  ${OFFER_FIELDS}
`;

export const CREATE_OFFER = gql`
  mutation offerCreate($input: OfferInput!) {
    offerCreate(input: $input) {
      ...OfferFields
    }
  }
  ${OFFER_FIELDS}
`;

export const BULK_CREATE_OFFER = gql`
  mutation offerBulkCreate($input: [OfferInput!]!) {
    offerBulkCreate(input: $input) {
      ...OfferFields
    }
  }
  ${OFFER_FIELDS}
`;

export const UPDATE_OFFER = gql`
  mutation offerUpdate($input: OfferInput!) {
    offerUpdate(input: $input) {
      ...OfferFields
    }
  }
  ${OFFER_FIELDS}
`;

export const DELETE_OFFER = gql`
  mutation offerDelete($id: ID!) {
    offerDelete(id: $id) {
      deletedIds
    }
  }
`;

export const UPDATE_OFFER_ORDER = gql`
  mutation offerUpdateOrder($input: [ID!]!) {
    offerUpdateOrder(input: $input) {
      ...OfferFields
    }
  }
  ${OFFER_FIELDS}
`;

export const GET_WIDGET_LOCATIONS = gql`
  query GetWidgetLocations {
    widgetLocations {
      id
      page {
        name
      }
      active
      selector
    }
  }
`;

export const UPDATE_WIDGET_LOCATION = gql`
  mutation updateWidgetLocation($input: UpdateWidgetLocationInput!) {
    widgetLocation: updateWidgetLocation(input: $input) {
      id
      active
      selector
    }
  }
`;

export const RECOMMENDATIONS = gql`
  query Recommendations {
    recommendations {
      ...RecommendationFields
    }
  }
  ${RECOMMENDATION_FIELDS}
`;

export const RECOMMENDATION = gql`
  query Recommendation($id: ID!) {
    recommendation(id: $id) {
      ...RecommendationFields
    }
  }
  ${RECOMMENDATION_FIELDS}
`;

export const CREATE_RECOMMENDATION = gql`
  mutation recommendationCreate($input: RecommendationInput!) {
    recommendationCreate(input: $input) {
      ...RecommendationFields
    }
  }
  ${RECOMMENDATION_FIELDS}
`;

export const UPDATE_RECOMMENDATION = gql`
  mutation recommendationUpdate($input: RecommendationInput!) {
    recommendationUpdate(input: $input) {
      ...RecommendationFields
    }
  }
  ${RECOMMENDATION_FIELDS}
`;

export const DELETE_RECOMMENDATION = gql`
  mutation recommendationDelete($ids: [ID!]!) {
    recommendationDelete(ids: $ids) {
      deletedIds
    }
  }
`;
