import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { FEATURES, UPDATE_FEATURE } from '@/query';

export const useFeature = (name) => {
  const { data, loading } = useQuery(FEATURES);
  const [updateFeature] = useMutation(UPDATE_FEATURE, {
    optimisticResponse: (vars) => ({
      updateFeature: {
        __typename: 'Store',
        features: {
          __typename: 'Features',
          [name]: vars.input[name],
        },
      },
    }),
  });
  const [isUpdating, setIsUpdating] = useState(false);

  const updateFeatureValue = async (value) => {
    setIsUpdating(true);
    try {
      await updateFeature({
        variables: {
          input: {
            [name]: value,
          },
        },
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return {
    value: data?.store?.features?.[name],
    update: updateFeatureValue,
    loading,
    isUpdating,
  };
};

export default useFeature;
